<template>
    <div id="sampleShow">
        <navbar title='示例展示' :back='true'></navbar>
        <img src="../../assets/images/other/sample.png" alt="sample">
    </div>
</template>
<script>
import navbar from "COMPS/accredit_navbar.vue";
export default {
    data() {
        return {
           
        };
    },
    components:{
        navbar
    }
}
</script>
<style  scoped>
    #sampleShow>img{
        width: 100%;
        height: 100%;
    }
</style>